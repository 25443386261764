@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    transition: background-color 0.5s ease;
  }

  body {
    font-family: "Poppins", "sans-serif";
  }
}

@layer components {
}

@layer utilities {
  .clip-message-box {
    clip-path: polygon(
      0 4%,
      100% 4%,
      100% 100%,
      0 100%,
      72% 0,
      91% 25%,
      0 100%
    );
  }

  .clip-message-box2 {
    clip-path: polygon(
      0 4%,
      100% 4%,
      100% 100%,
      0 100%,
      95% 0,
      100% 25%,
      0 100%
    );
  }
}

.input-cls {
  font-size: 13px !important;
  width: 70px !important;
}

.tag-cls {
  background-color: #ddbfef !important;
  color: #a831ec !important;
  font-size: 13px !important;
}
